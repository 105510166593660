import Vue from 'vue'
import VueRouter from 'vue-router'

let Login = () => import('../components/Login');
let Home = () => import('../components/Home');
let Welcome = () => import('../components/Welcome');
let Users = () => import('../components/user/Users');
let broker = () => import('../components/user/broker');
let Rights = () => import('../components/rights/Rights');
let Roles = () => import('../components/rights/Roles');
let Category = () => import('../components/goods/Category');
let Params = () => import('../components/goods/Params');
let damageRate = () => import('../components/goods/damageRate');
let GoodsList = () => import('../components/goods/List');
let GoodsAdd = () => import('../components/goods/listChildComponents/ListAdd');
let ListEdit = () => import('../components/goods/listChildComponents/ListEdit');
let Order = () => import('../components/order/Order');
let Commission = () => import('../components/commission/Commission');
let statistics = () => import('../components/statistics/statistics');
let deposit = () => import('../components/deposit/deposit');
let Report = () => import('../components/report/Report');
let bill = () => import('../components/bill/bill');
let billover = () => import('../components/bill/billover');
let question = () => import('../components/question/question');
let admin = () => import('../components/admin/Users');
let qrcode = () => import('../components/qrcode');
let relink = () => import('../components/relink');
let agreement = () => import('../components/question/agreement');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/qrcode',
    component: qrcode
  },
  {
    path: '/relink',
    component: relink
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: Welcome
      },
      {
        path: '/users',
        component: Users
      },
      {
        path: '/admin',
        component: admin
      },
      {
        path: '/rights',
        component: Rights
      },
      {
        path: '/roles',
        component: Roles
      },
      {
        path: '/categories',
        component: Category
      },
      {
        path: '/params',
        component: Params
      },
      {
        path: '/goods',
        component: GoodsList
      },
      {
        path: '/add',
        component: GoodsAdd
      },
      {
        path: '/ListEdit',
        component: ListEdit
      },
      {
        path: '/orders',
        component: Order
      },
      {
        path: '/reports',
        component: Report
      },
      {
        path: '/commission',
        component: Commission
      },
      {
        path: '/statistics',
        component: statistics
      },
      {
        path: '/deposit',
        component: deposit
      },
      {
        path: '/bill',
        component: bill
      },
      {
        path: '/billover',
        component: billover
      },
      {
        path: '/question',
        component: question
      },
      {
        path: '/damageRate',
        component: damageRate
      },
      {
        path: '/broker',
        component: broker
      },
      {
        path: '/agreement',
        component: agreement
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 如果访问的是登录页，直接放行
  if (to.path === '/qrcode') return next();
  if (to.path === '/relink') return next();
  // 如果访问的是登录页，直接放行
  if (to.path === '/login') return next();
  // 从sessionStorage中取出token值
  let token = sessionStorage.getItem('token');
  // 如果token不存在，说明没有登录，就强制跳转到登录页
  if (!token) {
    return next('/login');
  }
  next();
});

export default router
