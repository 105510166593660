import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [],
    totalMenu: [
      {
        id: 100,
        authName: "用户管理",
        path: "admin",
        children: [
          {
            id: 110,
            authName: "用户列表",
            path: "admin",
            children: [],
            order: null,
          },
          {
            id: 120,
            authName: "经纪人配置",
            path: "broker",
            children: [],
            order: null,
          },
        ],
        order: 1,
      },
      {
        id: 200,
        authName: "客户管理",
        path: "users",
        children: [
          {
            id: 210,
            authName: "客户列表",
            path: "users",
            children: [],
            order: null,
          },
        ],
        order: 2,
      },
      {
        id: 300,
        authName: "权限管理",
        path: "rights",
        children: [
          {
            id: 310,
            authName: "角色列表",
            path: "roles",
            children: [],
            order: 1,
          }
        ],
        order: 3,
      },
      {
        id: 400,
        authName: "商品管理",
        path: "goods",
        children: [
          {
            id: 410,
            authName: "商品列表",
            path: "goods",
            children: [
              { id: 411, authName: "查看" },
              { id: 412, authName: "添加" },
              { id: 413, authName: "编辑" },
              { id: 414, authName: "删除" },
            ],
            order: 1,
          },
          {
            id: 420,
            authName: "商品分类",
            path: "categories",
            children: [
              { id: 421, authName: "添加" },
              { id: 422, authName: "编辑" },
              { id: 423, authName: "删除" },
            ],
            order: 2,
          },
          {
            id: 430,
            authName: "商品折损率",
            path: "damageRate",
            children: [],
            order: 3,
          },
        ],
        order: 4,
      },
      {
        id: 500,
        authName: "问答管理",
        path: "question",
        children: [
          {
            id: 510,
            authName: "问答列表",
            path: "question",
            children: [
              { id: 511, authName: "添加" },
              { id: 512, authName: "编辑" },
              { id: 513, authName: "删除" },
            ],
            order: 1,
          },
          {
            id: 520,
            authName: "租赁协议",
            path: "agreement",
            children: [],
            order: 2,
          },
        ],
        order: 5,
      },
      {
        id: 600,
        authName: "订单管理",
        path: "orders",
        children: [
          {
            id: 610,
            authName: "订单列表",
            path: "orders",
            children: [
              { id: 611, authName: "修改流程" },
              { id: 612, authName: "关闭订单" },
              { id: 613, authName: "查看用户" },
            ],
            order: 1,
          },
        ],
        order: 6,
      },
      {
        id: 700,
        authName: "账单",
        path: "bill",
        children: [
          {
            id: 710,
            authName: "账单列表",
            path: "bill",
            children: [
              { id: 711, authName: "租金到款" },
              { id: 712, authName: "修改时间" },
              { id: 713, authName: "修改状态" },
            ],
            order: 1,
          },
          {
            id: 720,
            authName: "逾期账单",
            path: "billover",
            children: [
              { id: 711, authName: "租金到款" },
              { id: 712, authName: "修改时间" },
              { id: 713, authName: "修改状态" },
            ],
            order: 2,
          },
        ],
        order: 7,
      },
      {
        id: 800,
        authName: "押金",
        path: "deposit",
        children: [
          {
            id: 810,
            authName: "押金列表",
            path: "deposit",
            children: [],
            order: null,
          },
        ],
        order: 8,
      },
      {
        id: 900,
        authName: "佣金管理",
        path: "commission",
        children: [
          {
            id: 910,
            authName: "佣金列表",
            path: "commission",
            children: [],
            order: null,
          }
        ],
        order: 9,
      },
      {
        id: 1000,
        authName: "统计",
        path: "statistics",
        children: [
          {
            id: 1010,
            authName: "统计查询",
            path: "statistics",
            children: [],
            order: null,
          },
        ],
        order: 10,
      },
    ],
  },
  mutations: {
    setMenuList(state, obj) {
      state.menuList = obj
    }
  },
  actions: {
  },
  modules: {
  }
})
